<template>
  <!-- <div class="message-box item loader">
    <div class="item">
      <div class="item">
        <span>test</span>
        <Vue3Lottie class="!w-[150px] !h-[150px]" :animationData="CupScan" />
      </div>
    </div>
  </div> -->
  <div
    v-if="loaders.firstMessage"
    class="message-box item loader -mt-[4px] !h-[38.58px]"
  >
    <Writer :speed="20" :message="{ message: ' ' }" />
  </div>
  <div
    v-if="
      allMessages[1] &&
      allMessages[1].attachments?.length > 0 &&
      allMessages[1].attachments[0]?.type === attachmentTypes.LOTTIE
    "
    class="message-box item loader"
  >
    <tempate v-for="attachment in allMessages[1].attachments">
      <div class="item" v-if="attachment.type === attachmentTypes.LOTTIE">
        <span class="block mb-3">{{ attachment.text }}</span>
        <Vue3Lottie
          v-if="attachment.link.split('/').pop() == 'reading_scan.json'"
          class="!w-[150px] !h-[150px]"
          :class="getLottieClass(attachment.link)"
          :animationData="
            userSettings.userTheme == 'dark'
              ? ReadingScanDark
              : ReadingScanWhite
          "
        ></Vue3Lottie>
        <Vue3Lottie
          v-if="
            attachment.link &&
            attachment.link.split('/').pop() != 'reading_scan.json'
          "
          class="!w-[150px] !h-[150px]"
          :class="getLottieClass(attachment.link)"
          :animationData="getLottiePath(attachment.link)"
        />
      </div>
    </tempate>
  </div>

  <!-- message-loader finish -->

  <!-- .all-messages start -->
  <div class="item" v-for="(item, index) in allMessages" :key="item.session_id">
    <!-- .user-message start -->
    <TimeStamp
      :timestamp="item.timestamp_t"
      v-if="
        compareMessageDates(item.timestamp, allMessages[index + 1]?.timestamp)
      "
    />
    <div v-if="item.messageType == messageTypes.USER">
      <div
        :class="
          item.userMessageType === userMessageTypes.TEXT
            ? 'message-box user'
            : 'message message-user media'
        "
      >
        <template v-if="item.userMessageType === userMessageTypes.TEXT">
          <PasswordMessage
            v-if="item.lailaMessageType === userMessageTypes.PASSWORD"
            :compData="item"
          />
          <MessageBox v-else :compData="item" />
        </template>
        <ImageMessage
          v-else-if="item.userMessageType === userMessageTypes.IMAGE"
          :compData="item"
        />
      </div>

      <ReadReceipt :compData="item" />
    </div>
    <!-- .user-message finish -->
    <!-- .chatbot-message start -->

    <div v-else class="message-box flex flex-col chatbot relative">
      <Writer :speed="item.writerSpeed" :message="item" />

      <ShareLinks
        v-if="
          item.lailaMessageType === lailaMessageTypes.READING &&
          item.messageWriter
        "
        :withOriginalMessage="true"
        :message="item"
        :sharing="item.sharing"
      />
    </div>
    <!-- .chatbot-message finish -->

    <!-- .links start -->
    <div v-if="item.attachments?.length > 0">
      <template v-for="attachment in item.attachments">
        <Attachment
          v-if="attachment.type"
          :attachment="attachment"
          :message="item"
        />
      </template>
    </div>

    <!-- .links finish -->
    <!-- .ready-answers start -->
    <div
      v-if="
        item.readyAnswers &&
        item.readyAnswers.length > 0 &&
        !item.readyAnswersForReplySent &&
        item.messageWriter
      "
      class="ready-answers flex flex-col gap-[10px] items-center justify-center mt-[6px]"
    >
      <ReadyAnswers
        @handleAnswer="
          $emit('sendMessage', {
            query: answer,
            type: sendMessageTypes.readyAnswers,
            message: item,
          })
        "
        v-for="answer in item.readyAnswers"
        :answer="answer"
      />
    </div>
    <!-- .ready-answers finish -->
  </div>

  <!-- .all-messages finish -->
  <!-- .timestamp start -->
  <TimeStamp
    :timestamp="props.allMessages[props.allMessages.length - 1].timestamp_t"
    v-if="allMessages.length > 0"
  />

  <!-- .timestamp finish -->
</template>

<script setup>
  import {
    messageTypes,
    sendMessageTypes,
    userMessageTypes,
    attachmentTypes,
    lailaMessageTypes,
  } from "@/utils/enums"
  import Writer from "@/pages/components/messages/Writer.vue"
  import ReadyAnswers from "@/pages/components/messages/ReadyAnswers.vue"
  import Attachment from "@/pages/components/messages/Attachment.vue"
  import TimeStamp from "@/pages/components/messages/TimeStamp.vue"
  import ImageMessage from "@/pages/components/user/ImageMessage.vue"
  import MessageBox from "@/pages/components/user/MessageBox.vue"
  import PasswordMessage from "@/pages/components/user/PasswordMessage.vue"
  import ReadReceipt from "@/pages/components/user/ReadReceipt.vue"
  import { Vue3Lottie } from "vue3-lottie"
  import ShareLinks from "@/components/composables/ShareLinks.vue"

  import CupScan from "/public/assets/lotties/cup_scan.json"

  import ReadingScan8 from "/public/assets/lotties/reading_scan.json"
  import ReadingScan1 from "/public/assets/lotties/reading_scan1.json"
  import ReadingScan2 from "/public/assets/lotties/reading_scan2.json"
  import ReadingScan3 from "/public/assets/lotties/reading_scan3.json"
  import ReadingScan4 from "/public/assets/lotties/reading_scan4.json"
  import ReadingScan5 from "/public/assets/lotties/reading_scan5.json"
  import ReadingScan6 from "/public/assets/lotties/reading_scan6.json"
  import ReadingScan7 from "/public/assets/lotties/reading_scan7.json"
  import ReadingScan9 from "/public/assets/lotties/reading_scan9.json"
  import ReadingScanWhite from "/public/assets/lotties/reading_beyaz_zemin_1.json"
  import ReadingScanDark from "/public/assets/lotties/reading_siyah_zemin_1.json"

  import AudioPlayer from "@/pages/components/messages/attachment/AudioPlayer.vue"

  import QrScan from "/public/assets/lotties/qr_scan.json"
  import { userSettings } from "@/store/userSettings"
  import { watch } from "vue"

  const props = defineProps({
    allMessages: {
      type: Array,
      default: [],
      required: true,
    },
    loaders: {
      type: Object,
      default: {},
      required: true,
    },
  })

  const compareMessageDates = (currDate, prevDate) => {
    if (currDate === undefined || prevDate === undefined) {
      return false
    }
    const d1 = new Date(currDate)
    const d2 = new Date(prevDate)

    return (
      d1.getFullYear() != d2.getFullYear() ||
      d1.getMonth() != d2.getMonth() ||
      d1.getDate() != d2.getDate()
    )
  }

  const lotties = [
    ReadingScan1,
    ReadingScan2,
    ReadingScan3,
    ReadingScan4,
    ReadingScan5,
    ReadingScan6,
    ReadingScan7,
    ReadingScan8,
    ReadingScan9,
  ]

  const getLottiePath = (data) => {
    if (!data) return
    const caughtLottie = data.split("/").pop()
    if (caughtLottie === "cup_scan.json") return CupScan
    if (caughtLottie === "qr_scan.json") return QrScan
    if (caughtLottie === "reading_scan.json") {
      return userSettings.userTheme == "dark"
        ? ReadingScanDark
        : ReadingScanWhite
      var randomScan = Math.floor(Math.random() * 8) + 1
      return lotties[randomScan]
    }
  }

  watch(
    userSettings,
    (v) => {
      getLottiePath
      console.log("v", v)
    },
    { deep: true }
  )

  const getLottieClass = (data) => {
    if (!data) return
    const caughtLottie = data.split("/").pop()
    if (caughtLottie === "cup_scan.json") return "cupp-scan-lottie"
    if (caughtLottie === "qr_scan.json") return "qr-scan-lottie"
    if (caughtLottie === "reading_scan.json") return "reading-scan-lottie"
  }

  const fakeSharingData = {
    title: "Fortune Teller Coffee",
    text: "Take a look at my Turkish coffee cup digitized by Fortune Teller Coffee",
    link: "https://ftc-mesh-xqc4cbw3kq-ew.a.run.app/{session_id}",
  }
</script>
