<template>
  <div
    class="application-header w-full bg-theme-primary min-h-[45px] flex items-center justify-between px-[15px]"
  >
    <Logo @click="openLogo" />
    <RightSide />
  </div>
</template>
<script setup>
  import Logo from "@/components/global/header/Logo.vue"
  import RightSide from "@/components/global/header/RightSide.vue"
  import { box } from "@/utils/box"
  import ImageModal from "@/components/composables/modal/ImageModal.vue"
  import { reactive } from "vue"

  const openLogo = () => {
    box.openModal(
      ImageModal,
      {
        image: "/assets/images/logo/laila_avatar_full.png",
        transition: "nested",
      },
      "w-[80%]"
    )
  }
</script>
