<template>
  <div @click="showImageForPopup(attachment.link)" class="message media">
    <img class="w-full" :src="attachment.link" />
    <ShareLinks v-if="message.sharing" :sharing="message.sharing" />
  </div>
</template>

<script setup>
  import ImageModal from "@/components/composables/modal/ImageModal.vue"
  import { box } from "@/utils/box"

  const props = defineProps({
    message: {
      type: Object,
      default: {},
      required: true,
    },
    attachment: {
      type: Object,
      default: {},
      required: true,
    },
  })

  const showImageForPopup = (image) => {
    box.openModal(ImageModal, { image }, "w-[92%]")
  }
</script>
